import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import Layout from '../../components/layout'
import * as styles from './index.module.scss'

import IconQuality from '../../images/about/icon-quality.inline.svg'
import IconSewing from '../../images/about/icon-sewing.inline.svg'
import IconPalette from '../../images/about/icon-palette.inline.svg'

const AboutPage = () => {
  return (
    <Layout
      pageTitle="О нас"
      className={styles.content}
    >
      <section className={styles.intro}>
        Мы знаем, насколько важен полноценный и комфортный отдых, поэтому стараемся предлагать максимально качественный текстиль.
      </section>

      <article className={styles.statement}>
        <section className={styles.text}>
          <header>
            <IconQuality className={styles.icon} />
            <h3>
              Качественные материалы
            </h3>
          </header>
          <div className={styles.description}>
            Вся наша продукция создаётся на импортном оборудовании с использованием новейших текстильных технологий. Все изделия торговой марки Stretch Jersy по умолчанию гипоаллергенны, изготовлены из высококачественных натуральных материалов и сертифицированы.
         </div>
        </section>

        <section className={styles.illustration}>
          <StaticImage
            src="../../images/about/photo-quality.jpg"
            alt="Высокое качество"
          />
        </section>
      </article>

      <article className={`${styles.statement} ${styles.reversed}`}>
        <section className={styles.text}>
          <header>
            <IconSewing className={styles.icon} />
            <h3>
              Неповторимый фирменный шов
            </h3>
          </header>
          <div className={styles.description}>
            Мы используем только качественные немецкие нитки, которые подбираются в тон к изделию. Никаких торчащих ниток и неровных швов!
          </div>
        </section>

        <section className={styles.illustration}>
          <StaticImage
            src="../../images/about/photo-sewing.jpg"
            alt="Идеально закрытые швы"
          />
        </section>
      </article>

      <article className={styles.statement}>
        <section className={styles.text}>
          <header>
            <IconPalette className={styles.icon} />
            <h3>
              Большая палитра цветов
            </h3>
          </header>
          <div className={styles.description}>
            В нашем ассортименте всегда большой выбор расцветок на любой вкус. Коллекции постоянно пополняются новыми цветами. Порадуйте себя разнообразными сочетаниями!
          </div>
        </section>
        <section className={styles.illustration}>
          <StaticImage
            src="../../images/about/photo-palette.jpg"
            alt="Большая палитра цветов"
          />
        </section>
      </article>
    </Layout>
  )
}

export default AboutPage